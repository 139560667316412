import { Injectable } from '@angular/core';
import { App, CurrentUserAppsGQL, CurrentUserAppsQuery } from '@hxp/graphql';
import * as R from 'ramda';
import { Observable, filter, map } from 'rxjs';
import {
  filterAccountAppsByStatus,
  filterOutServiceAndTemplateApps,
  filterSubscribedAppsByEnvironment,
  filterSubscribedAppsByStatus,
} from './app-list.utils';

export interface CurrentUserApp {
  readonly launchUrl?: string | null;
  readonly app: Pick<App, 'localizedName'>;
}

@Injectable()
export class AppListService {
  constructor(private readonly _currentUserAppsGQL: CurrentUserAppsGQL) {}

  private static filterCurrentUserApps(
    currentUser: CurrentUserAppsQuery['currentUser'],
    hideNonProvisionedApps: boolean,
    environmentId?: string,
  ): readonly CurrentUserApp[] {
    const { accountApps, subscribedApps } = currentUser;

    // filter out non-provisioned apps if hideNonProvisionedApps is true
    const filterNonProvisionedApps = R.ifElse(R.always(hideNonProvisionedApps), filterSubscribedAppsByStatus, R.identity);
    const filteredAccountApps = hideNonProvisionedApps ? filterAccountAppsByStatus(accountApps) : accountApps;

    const filteredSubscribedApps = R.pipe(
      filterNonProvisionedApps,
      filterSubscribedAppsByEnvironment(environmentId),
      filterOutServiceAndTemplateApps,
    )(subscribedApps);

    return [...filteredSubscribedApps, ...filteredAccountApps];
  }

  private static sortAppsByLocalizedName(apps: readonly CurrentUserApp[]): readonly CurrentUserApp[] {
    return [...apps].sort((a, b) => a.app.localizedName.localeCompare(b.app.localizedName));
  }

  getCurrentUserApps(hideNonProvisionedApps: boolean, environmentId?: string): Observable<readonly CurrentUserApp[]> {
    return this._currentUserAppsGQL.watch().valueChanges.pipe(
      filter((result) => !!result.data),
      map(({ data }) => {
        const apps = AppListService.filterCurrentUserApps(data.currentUser, hideNonProvisionedApps, environmentId);
        return AppListService.sortAppsByLocalizedName(apps);
      }),
    );
  }
}
