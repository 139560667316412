<mat-card class="h-full" *transloco="let t; read: 'user-portal.applications-list'">
  <mat-card-header class="pt-0">
    <mat-card-title>{{ t('title') }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="pt-6 pb-0">
    <ng-container *ngIf="appList$ | async as appList; else appListGhost">
      <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4" *ngIf="appList.length; else emptyState">
        <hxp-portal-app-tile *ngFor="let subscribedApp of appList" [appName]="subscribedApp.app.localizedName" [launchUrl]="subscribedApp.launchUrl" />
      </div>
      <ng-template #emptyState>
        <hxp-portal-empty-app-list />
      </ng-template>
    </ng-container>
    <ng-template #appListGhost>
      <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
        <hxp-portal-app-tile [loading]="true" />
        <hxp-portal-app-tile [loading]="true" />
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
