import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { FeatureFlagService, KernelComponentsModule, isFlagEnabled } from '@hxp/kernel';
import { HyGhostModule } from '@hyland/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable, switchMap } from 'rxjs';
import { AppListService, CurrentUserApp } from './app-list.service';
import { AppTileComponent } from './components/app-tile/app-tile.component';
import { EmptyAppListComponent } from './components/empty-app-list/empty-app-list.component';

@Component({
  standalone: true,
  selector: 'hxp-portal-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
  imports: [CommonModule, MatCardModule, TranslocoModule, HyGhostModule, KernelComponentsModule, AppTileComponent, EmptyAppListComponent],
  providers: [AppListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppListComponent implements OnChanges, OnInit {
  @Input()
  environmentId?: string;

  @HostBinding('attr.data-testid')
  dataTestId = 'my-app-list';

  appList$!: Observable<readonly CurrentUserApp[]>;

  constructor(private readonly _appListService: AppListService, private readonly _featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this._initAppList();
  }

  ngOnChanges(): void {
    this._initAppList();
  }

  private _initAppList() {
    const showOverrideFlagOptions = 'nucleus-ce-non-provisioned-apps';

    this.appList$ = this._featureFlagService.getFlag(showOverrideFlagOptions).pipe(
      switchMap((flagValue) => {
        const isEnabled = isFlagEnabled(flagValue);
        return this._appListService.getCurrentUserApps(isEnabled, this.environmentId);
      }),
    );
  }
}
