import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { HY_DIALOG_DATA } from '@hyland/ui';

export interface EnvironmentSelectorDialogData {
  readonly id: string;
  readonly name: string;
  readonly selected: boolean;
}

@Component({
  templateUrl: './environment-selector.dialog.html',
  styleUrls: ['./environment-selector.dialog.scss'],
})
export class EnvironmentSelectorDialog implements AfterViewInit {
  selectedEnvironment: EnvironmentSelectorDialogData | undefined;
  sortedEnvironmentList: readonly EnvironmentSelectorDialogData[];

  @ViewChild(MatSelectionList, { static: false })
  readonly environmentList!: MatSelectionList;

  constructor(@Inject(HY_DIALOG_DATA) readonly data: { environmentList: EnvironmentSelectorDialogData[] }) {
    this.selectedEnvironment = data.environmentList.find((e) => e.selected);
    this.sortedEnvironmentList = [...data.environmentList].sort((a, b) => a.name.localeCompare(b.name));
  }

  ngAfterViewInit() {
    this.environmentList.selectedOptions.selected[0]?.focus();
  }

  updateSelectedEnvironment(changeEvent: MatSelectionListChange) {
    this.selectedEnvironment = changeEvent.source.selectedOptions.selected[0]?.value;
  }
}
