<div *transloco="let t; read: 'kernel.components.environment-selector-dialog'">
  <hy-dialog
    class="dialog"
    #dialog
    confirmLabel="{{ t('change-button-text') }}"
    dismissLabel="{{ t('cancel-button-text') }}"
    (confirmed)="dialog.close(selectedEnvironment)"
    (dismissed)="dialog.close()"
    [header]="t('header')"
  >
    <div class="content">
      <div class="mt-6 mat-headline-3">{{ t('description-available-environments') }}</div>
      <mat-selection-list hyList class="list my-4 mx-0 pb-2" multiple="false" (selectionChange)="updateSelectedEnvironment($event)">
        <mat-list-option
          [value]="environment"
          class="item"
          hyListItem
          [ngClass]="{ 'environment-selected': environment.id === selectedEnvironment?.id }"
          [hyListItemActive]="environment.id === selectedEnvironment?.id"
          [selected]="environment.id === selectedEnvironment?.id"
          *ngFor="let environment of sortedEnvironmentList"
        >
          <mat-icon class="icon" hyIcon mat-list-icon>check</mat-icon>
          <div mat-line data-testid="available-environments-from-list">{{ environment.name }}</div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </hy-dialog>
</div>
