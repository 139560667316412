<ng-container *transloco="let t; read: 'user-portal.applications-list'">
  <a
    *ngIf="!loading; else loadingTemplate"
    (mousedown)="setPressState(true)"
    (mouseup)="setPressState(false)"
    (mouseleave)="setPressState(false)"
    [attr.href]="launchUrl"
    target="_blank"
    class="app-tile no-underline"
    [ngClass]="{ pressed }"
    [attr.aria-label]="t('go-to-application-aria-label', { appName })"
  >
    <div class="app-tile__avatar h-full box-border py-6 px-4 relative">
      <div class="absolute left-0 top-0 w-full h-full opacity-hy-30" [ngClass]="colorClass"></div>
      <div class="app-tile__avatar__tile flex items-center justify-center text-center rounded-12" [ngClass]="colorClass">
        <p class="text-white m-0 font-bold text-xl">{{ appName | slice: 0 : 2 | uppercase }}</p>
      </div>
    </div>
    <div class="flex grow items-center p-4">
      <p class="app-name m-0 font-semibold text-base" data-testid="my-app-list-element">{{ appName }}</p>
    </div>
  </a>
</ng-container>

<ng-template #loadingTemplate>
  <div class="app-tile app-tile__ghost no-underline">
    <hy-ghost-block class="w-full h-full" />
  </div>
</ng-template>
