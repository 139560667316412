import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserPortalEnvironmentsGQL } from '@hxp/graphql';
import { EnvironmentContextService } from '@hxp/kernel';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentIdResolverService  {
  constructor(
    private readonly _environmentQuery: UserPortalEnvironmentsGQL,
    private readonly _environmentContext: EnvironmentContextService,
    private readonly _router: Router,
  ) {}

  resolve() {
    return this._environmentQuery.watch().valueChanges.pipe(
      filter((environmentQueryResult) => !!environmentQueryResult.data),
      map((environmentQueryResult) => {
        const environmentIds: string[] = [];

        return environmentQueryResult.data.currentUser.subscribedApps
          .filter((app) => {
            if (app.environment && environmentIds.indexOf(app.environment.id) === -1) {
              environmentIds.push(app.environment.id);
              return true;
            }
            return false;
          })
          .map((app) => app.environment!.id);
      }),
      tap((environmentIds) => {
        if (!environmentIds.length) {
          void this._router.navigate(['unassigned']);
          return;
        }

        const storedEnvironmentId = this._environmentContext.getSelectedEnvironmentValue();
        const matchingEnvironmentId = environmentIds.find((environmentId) => environmentId === storedEnvironmentId);
        const targetEnvironmentId = matchingEnvironmentId || environmentIds[0];

        void this._router.navigate(['environments', targetEnvironmentId]);
      }),
    );
  }
}
