<ng-container *ngIf="accountApps$ | async as accountApps">
  <hy-hx-shell homeRoute="/" [linkItems]="accountApps" toolbarTitle="Experience User Portal">
    <ng-container *transloco="let t; read: 'user-portal.toolbar'">
      <hy-shell-toolbar *ngIf="hyAuthService.authenticated$ | async as authenticated">
        <ng-container *hyShellToolbarContent>
          <div class="flex justify-between flex-row items-center w-full">
            <div class="flex flex-row items-center">
              <hxp-kernel-feature-flags class="mr-4" *hxpKernelFeatureFlag="{ flag: 'nucleus-ce-show-override-flag-options' }"/>

              <div *ngIf="authenticated === true" class="mr-4">
                <ng-container
                  *ngIf="{ environments: environments$ | async, selectedEnvironment: selectedEnvironment$ | async } as environmentData"
                >
                  <ng-container *ngIf="environmentData.environments && environmentData.selectedEnvironment">
                    <button
                      mat-stroked-button
                      *ngIf="environmentData.environments.length > 1; else singleEnvironment"
                      data-testid="environment-selector-button"
                      attr.aria-label="{{ t('switch-environment-button-aria-text') }}"
                      matTooltip="{{ t('switch-environment-button-tooltip') }}"
                      (click)="switchEnvironment()"
                    >
                      {{ environmentData.selectedEnvironment.name }}
                    </button>
                    <ng-template #singleEnvironment>
                      <span class="environment-name py-2 -mr-2">
                        {{ environmentData.selectedEnvironment.name }}
                      </span>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </hy-shell-toolbar>
    </ng-container>
  </hy-hx-shell>
</ng-container>
