import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserPortalEnvironmentsGQL } from '@hxp/graphql';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EnvironmentValidationGuard  {
  constructor(private readonly _environmentQuery: UserPortalEnvironmentsGQL, private readonly _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const environmentIdFromRoute = route.paramMap.get('environmentId');

    return this._environmentQuery.watch().valueChanges.pipe(
      filter((environmentQueryResult) => !!environmentQueryResult.data),
      map((environmentQueryResult) => {
        const environmentIds: string[] = [];

        return environmentQueryResult.data.currentUser.subscribedApps
          .filter((app) => {
            if (app.environment && environmentIds.indexOf(app.environment.id) === -1) {
              environmentIds.push(app.environment.id);
              return true;
            }
            return false;
          })
          .map((app) => app.environment!);
      }),
      map((environments) => {
        if (!environments.length) {
          void this._router.navigateByUrl('unassigned');
          return false;
        }

        const environmentMatchingRouteParam = environments.find((environment) => environment.id === environmentIdFromRoute);
        if (!environmentMatchingRouteParam) {
          void this._router.navigateByUrl('page-cannot-be-viewed');
          return false;
        }

        return true;
      }),
    );
  }
}
