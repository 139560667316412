import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentContextService, routeParameter } from '@hxp/kernel';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './dashboard.view.html',
  styleUrls: ['./dashboard.view.scss'],
})
export class DashboardView {
  readonly environmentId$?: Observable<string>;

  constructor(route: ActivatedRoute, private readonly _environmentContext: EnvironmentContextService) {
    this.environmentId$ = route.params.pipe(
      routeParameter<string>('environmentId'),
      tap((environmentId) => {
        const storedEnvironmentId = this._environmentContext.getSelectedEnvironmentValue();
        if (environmentId !== storedEnvironmentId) {
          this._environmentContext.selectEnvironment(environmentId);
        }
      }),
    );
  }
}
