import { AccountAppProvisioningStatus, AppType, CurrentUserAppsQuery, SubscribedAppProvisioningStatus } from '@hxp/graphql';

export type SubscribedApp = CurrentUserAppsQuery['currentUser']['subscribedApps'][0];
export type AccountApp = CurrentUserAppsQuery['currentUser']['accountApps'][0];

export const filterSubscribedAppsByStatus = (apps: SubscribedApp[]) => {
  return apps.filter(
    (app) =>
      app.provisioningStatus === SubscribedAppProvisioningStatus.Provisioned ||
      app.provisioningStatus === SubscribedAppProvisioningStatus.Carriedover,
  );
};

export const filterOutServiceAndTemplateApps = (apps: SubscribedApp[]) => {
  return apps.filter((app) => app.app.appType !== AppType.Service && app.app.appType !== AppType.Template);
};

export const filterAccountAppsByStatus = (apps: AccountApp[]) => {
  return apps.filter((app) => app.provisioningStatus === AccountAppProvisioningStatus.Provisioned);
};

export const filterSubscribedAppsByEnvironment = (environmentId?: string) => (apps: SubscribedApp[]) => {
  return apps.filter((app) => app.environmentId === environmentId);
};
